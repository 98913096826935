import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HttpLink } from 'apollo-link-http';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from 'react-apollo';
import { setContext } from 'apollo-link-context';
import { I18nextProvider } from 'react-i18next';
import { onError } from 'apollo-link-error';

import App from './App';
import { getInternalToken } from 'utils';
import './index.css';
import i18n from './i18n';

import 'bootstrap/dist/css/bootstrap.min.css';

const cache = new InMemoryCache({
    dataIdFromObject: o => `${o.__typename}-${o.id}`,
});

const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const authLink = setContext((_, { headers }) => ({
    headers: {
        ...headers,
        Authorization: `Bearer ${getInternalToken()}`,
    },
}));

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) => {
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );
            return message;
        });
    if (networkError) {
        console.log('networkError', networkError);
        // window.location.assign(ERROR);
    }
});

const client = new ApolloClient({
    link: errorLink.concat(authLink.concat(httpLink)),
    cache: cache.restore(window.__APOLLO_STATE__ || {}),
});

//for one active tab
localStorage.openpages = Date.now();
var onLocalStorageEvent = function (e) {
    if (e.key === 'openpages') {
        localStorage.page_available = Date.now();
    }
    if (e.key === 'page_available') {
        window.location.href = 'singleApp.html';
    }
};
window.addEventListener('storage', onLocalStorageEvent, false);

ReactDOM.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <BrowserRouter>
                <I18nextProvider i18n={i18n}>
                    <App />
                </I18nextProvider>
            </BrowserRouter>
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
