import gql from "graphql-tag";

export const UserFragment = gql`
  fragment User on UserType {
    id
    dateJoined
    lastName
    isSuperuser
    email
    firstName
    lastName
    avatar
    workingTime
  }
`;

export const TeamFragment = gql`
  fragment Team on TeamType {
    id
    name
    users {
      ...User
    }
  }
  ${UserFragment}
`;

export const JobTitleFragment = gql`
  fragment Job on JobTitleType {
    id
    title
    user {
      ...User
    }
  }
  ${UserFragment}
`;

export const ProjectFragment = gql`
  fragment Project on ProjectType {
    id
    name
    icon
    status
    highlight
    team {
      ...Team
    }
  }
  ${TeamFragment}
`;
