import moment from "moment";
import { myStatisticsPerDwm, myStatPerDwmForProj } from "../api/queries";
/*eslint-disable*/

export const saveData = (token) => {
  const timestamp = +new Date();
  const data = JSON.stringify({ token, timestamp });
  localStorage.setItem("token", data);
};

export const getCurrentDate = (date) => {
  let today = date;
  let day = today.getDate();
  let month = today.getMonth() + 1;
  let yyyy = today.getFullYear();
  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  return (today = `${yyyy}-${month}-${day}`);
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const strConvertToMs = (str) => {
  const [hours, minutes, seconds] = str.split(":").map(Number);
  return hours * 3600000 + minutes * 60000 + seconds * 1000;
};

export const strConvertToSS = (str) => {
  const [hours, minutes, seconds] = str.split(":").map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};

export const ssConvertToStr = (sec_num) => {
  let hours = Math.floor(sec_num / 3600)
    .toString()
    .padStart(2, "0");
  let minutes = Math.floor((sec_num % 3600) / 60)
    .toString()
    .padStart(2, "0");
  let seconds = (sec_num % 60).toString().padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
};

export const msConvertToSS = (ms) => {
  return ms / 1000;
};

export const dateToDay = () => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = monthNames[today.getMonth()];
  return (today = dd + " " + mm);
};

export const generateUniqueCode = (length) => {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789*_-?/+=$";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const checkCurentDate = (oldDate) => {
  let date = moment().utc().format("YYYY-MM-DD");
  if (oldDate !== date) {
    window.location.reload();
    return;
  }
};

export const shortTime = (str) => {
  let time_parts = str.split(":");
  return `${time_parts[0]}:${time_parts[1]}`;
};

export const saveTime = (seconds) => {
  localStorage.setItem(
    "last_tracked_time",
    JSON.stringify({
      tracked_time: ssConvertToStr(seconds),
      time: Date.now(),
    }),
  );
};

export const calculateWorkDayTemplate = (
  calculateWorkDay,
  projectId,
  seconds,
  trackingSession,
  isOnline,
  date,
) => {
  if (!date) {
    date = moment().format("YYYY-MM-DD");
  }

  return calculateWorkDay({
    variables: {
      projectId: projectId,
      seconds: seconds,
      date: date,
      sessionId: trackingSession.session_id,
      timestamp: trackingSession.timestamp,
      online: isOnline,
    },
    fetchPolicy: "no-cache",
  });
};

export const compareCurrentUTCWithLastRequest = (lastRequestDate) => {
  let localDate = moment().local().format("YYYY-MM-DD HH:mm:ss");
  var convertedUTClastRequestToLocal = moment
    .utc(lastRequestDate)
    .local()
    .format("YYYY-MM-DD HH:mm:ss");
  const isNewDay = !moment(convertedUTClastRequestToLocal).isSame(
    localDate,
    "day",
  );

  return isNewDay;
};

export const startWeek = moment().weekday(1).format("D MMM");
export const endWeek = moment().weekday(7).format("D MMM");
export const month = moment().format("MMMM");
export const year = moment().format("YYYY");

export const getInternalToken = () => {
  const data = localStorage.getItem("token");
  const token = data ? JSON.parse(data).token : "";
  return token;
};

export const getExpirationDate = (token) => {
  if (!token) return null;

  const jwt = JSON.parse(Buffer.from(token.split(".")[1], "base64"));

  return (jwt && jwt.exp && jwt.exp * 1000) || null;
};

export const isExpires = (token) => {
  const exp = getExpirationDate(token);
  // Refresh the token a hour early to avoid latency issues
  const expirationTime = exp - 3600000;
  return Date.now() >= expirationTime;
};
