import React, { useEffect } from 'react';
import { useApolloClient, useQuery } from 'react-apollo';
import { useHistory, Redirect } from 'react-router-dom';

import * as path from 'constants/routes';
import * as mutate from 'api/mutations';
import { User } from 'api/queries';
import { getInternalToken, isExpires, saveData } from 'utils';

export const withAuth = ProtectedRoute => props => {
    const client = useApolloClient();
    const { loading, error, data } = useQuery(User);

    const history = useHistory();
    const token = getInternalToken();

    useEffect(() => {
        checkAutorization();
    }, []);

    const checkAutorization = async () => {
        if (!token) {
            return clearStorageAndRedirect();
        }

        if (isExpires(token)) {
            const isRefreshed = await refreshToken(token);
            if (!isRefreshed) return clearStorageAndRedirect();
        }

        return Promise.resolve();
    };

    const refreshToken = async token => {
        try {
            const result = await client.mutate({
                mutation: mutate.REFRESH_TOKEN,
                variables: { token },
                fetchPolicy: 'no-cache',
            });
            if (result?.data) {
                localStorage.removeItem('token');
                saveData(result.data.refreshToken.token);
                return result.data.refreshToken;
            }
            return null;
        } catch (error) {
            return null;
        }
    };

    const clearStorageAndRedirect = () => {
        history.push(path.SIGN_IN);
        window.localStorage.removeItem('token');
        client.cache.reset();
    };

    if (loading) return null;

    if (error) {
        window.localStorage.removeItem('token');
        return <Redirect to={path.SIGN_IN} />;
    }

    const userData = {
        user: {
            ...data.me,
        },
    };

    return <ProtectedRoute userData={userData} {...props} />;
};
