import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { withAuth } from "hocs/PrivateRoute";
import Context from "./Context";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  main: {
    position: "relative",
    width: "375px",
    height: "667px",
    background: "#FFFFFF",
    overflow: "hidden",
    [theme.breakpoints.down(600)]: {
      width: "100%",
      height: "100%",
    },
    "@media all and (display-mode: standalone )": {
      width: "100%",
      height: "100%",
    },
  },
}));

const Main = ({ children, userData }) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const pages = {
    page,
    setPage: () => setPage((prev) => prev + 1),
  };

  return (
    <Context.Provider value={pages}>
      <div className={classes.root}>
        <main className={classes.main}>{children(userData)}</main>
      </div>
    </Context.Provider>
  );
};

export default withAuth(Main);
